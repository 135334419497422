/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import styled from '@emotion/styled';
import {jsx} from '@emotion/react';
import {Trans} from 'gatsby-plugin-react-i18next';

import {countries} from '../../helpers/constants/common';
import {H6, h6BaseStyle, Hero, P, UL} from '../common/Typography';
import {breakpoints, colors, fontWeights} from '../../styles/theme';
import ChannelImagesCarousel from './ChannelDetailsCarousel';


/*
 * Private Elements
 */
const StyledChannelDetailsContainer = styled.div`
    .payment-container,
    .provider-container {
        padding: 56px 20px;

        .description {
            ${h6BaseStyle};
            font-weight: normal;
            margin-bottom: 100px;
        }
    }

    .payment-container {
        background: ${colors.white} linear-gradient(to top, #F0F0FF 0%, #FFFFFF 500px);
    }

    ${UL} {
        columns: 2;
        list-style-type: none;
        padding: 0;
        font-size: 14px;
    }
  
    @media (min-width: ${breakpoints.md}) {
        .payment-container,
        .provider-container {
            padding: 80px 110px;

            .description {
                font-size: 20px;
            }
        }

        ${UL} {
            columns: 5;
        }
    }
`;


const StyledSectionTitle = styled(P)`
    text-transform: uppercase;
    font-weight: ${fontWeights.semiBold};
    color: ${colors.mediumGrey};
`;


const StyledHeadingImageContainer = styled.div`
    margin-top: 40px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${Hero} {
      flex-basis: 80%;
    }

    .image-container {
        width: 50px;
        height: 50px;
    }

    @media (min-width: ${breakpoints.md}) {
        margin-top: 54px;

        .image-container {
            width: 100px;
            height: 100px;
        }
    }
`;


const FullWidthContainer = styled.div`
    width: calc(100vw - 10px);
    margin: 0 0 0 calc(-50vw + 50%);
`;


/*
 * Public Elements
 */
const ChannelDetails = ({channel}) => {
    const channelPaymentMethodName = channel.payment_method.name;

    return (
        <StyledChannelDetailsContainer>

            <div className="payment-container">
                <StyledSectionTitle as="h2">
                    <Trans>Payment Method</Trans>
                </StyledSectionTitle>
                <StyledHeadingImageContainer>
                    <Hero as="h3">{channel.payment_method.name}</Hero>
                    <div className="image-container">
                        <GatsbyImage
                            image={getImage(channel.payment_method.logo.localFile)}
                            alt={`${channel.payment_method.name} logo`}
                        />
                    </div>
                </StyledHeadingImageContainer>

                <div className="row">
                    <p className="description col-md-10 col-xl-8">{channel.payment_method.description}</p>
                </div>

                {channel.payment_method.countries.length > 0 && (
                    <div>
                        <H6 as="p">
                            <Trans>
                                See where
                                {' '}
                                {{paymentMethodName: channelPaymentMethodName}}
                                {' '}
                                is available
                            </Trans>
                        </H6>
                        <UL>
                            {channel.payment_method.countries.map(country => (
                                <li key={country.name}>{countries[country.name]}</li>
                            ))}
                        </UL>
                    </div>
                )}
            </div>

            <div className="provider-container">
                <StyledSectionTitle as="h2"><Trans>Provider</Trans></StyledSectionTitle>
                <StyledHeadingImageContainer>
                    <Hero as="h3">{channel.payment_provider.name}</Hero>
                    <div className="image-container">
                        <GatsbyImage
                            image={getImage(channel.payment_provider.logo.localFile)}
                            alt={`${channel.payment_provider.name} logo`}
                        />
                    </div>
                </StyledHeadingImageContainer>

                <div className="row">
                    <p className="description col-md-10 col-xl-8">{channel.payment_provider.description}</p>
                </div>

                {channel.screenshots.length > 0 && (
                    <FullWidthContainer>
                        <Hero as="h3" className="text-center mb-5"><Trans>How it Works</Trans></Hero>
                        <ChannelImagesCarousel images={channel.screenshots} />
                    </FullWidthContainer>
                )}

            </div>

        </StyledChannelDetailsContainer>
    );
};

ChannelDetails.propTypes = {
    channel: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default ChannelDetails;
