/** @jsx jsx */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';

import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowSVG from '../../images/clickables/cta_arrow.svg';
import DividerArrow from '../../images/channels/divider-arrow.png';
import {breakpoints, colors, fonts} from '../../styles/theme';


/*
 * Private Elements
 */
const StyledButton = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${colors.white};
    box-shadow: 0 2px 7px 0 ${colors.shadowGrey};
    display: flex;
    align-content: center;
    border: 0;
    padding: 0;

    position: absolute;
    bottom: -70px;

    &.arrow-prev {
        right: calc(50vw + 20px);
    }
    &.arrow-next {
        left: calc(50vw + 20px);
    }

    &.arrow-disabled {
        display: none;
    }
`;

const ArrowCircleButton = ({className, invertedDirection, onClick}) => {
    const {t} = useTranslation();

    const svgCss = css`
        margin: auto;
        path {
            stroke: ${colors.darkPurple};
        }
    `;

    const invertTransform = css`
        transform: scale(-1, 1);
    `;

    const arrowCss = invertedDirection ? [svgCss, invertTransform] : svgCss;

    const label = invertedDirection ? t('Previous') : t('Next');

    // eslint-disable-next-line i18next/no-literal-string
    let classes = invertedDirection ? 'arrow-prev' : 'arrow-next';

    if (className.includes('slick-disabled')) {
        classes = `${classes} arrow-disabled`;
    }

    return (
        <StyledButton type="button" aria-label={label} className={classes} onClick={onClick}>
            <ArrowSVG css={arrowCss} />
        </StyledButton>
    );
};

ArrowCircleButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    invertedDirection: PropTypes.bool,
};

ArrowCircleButton.defaultProps = {
    onClick: () => {},
    className: '',
    invertedDirection: false,
};


const sliderCss = css`
    .slick-track {
        display: flex;
    }

    .slick-slide div  {
        outline: none !important;
    }
`;


const StyledSlide = styled.div`
    min-width: 200px;
    padding: 0 20px;

    &.dashed {
        margin-right: 25px;
        border-right: 25px solid transparent;
        border-image: url(${DividerArrow});
        border-image-slice: 200;
    }

    @media (min-width: 600px) {
        min-width: 250px;

        &.dashed {
            border-image-slice: 100;
        }
    }

    @media (min-width: 1000px) {
        min-width: 300px;

        &.dashed {
            border-image-slice: 40;
        }
    }
`;


const StyledSlideDescription = styled.div`
    position: relative;
    top: 100px;
    padding-bottom: 160px;

    p {
        font-family: ${fonts.sansSerif};
        text-align: center;
        position: absolute;
        left: 10%;
        width: 80%;

        @media (min-width: ${breakpoints.md}) {
            left: calc(-300px + 50%);
            width: 600px;
        }
    }
`;


/*
 * Public Elements
 */
const ChannelImagesCarousel = ({images}) => {
    // index of the current slide, to show the description accordingly
    const [sliderIndex, setSliderIndex] = useState(0);

    const sliderSettings = {
        infinite: false,
        centerMode: true,
        variableWidth: true,
        speed: 300,
        swipeToSlide: true,
        focusOnSelect: true,
        prevArrow: <ArrowCircleButton invertedDirection />,
        nextArrow: <ArrowCircleButton />,
        beforeChange: (current, next) => setSliderIndex(next),
    };

    return (
        <div>
            <Slider {...sliderSettings} css={sliderCss}>
                {images.map((image, i) => (
                    <StyledSlide
                        key={i} // eslint-disable-line react/no-array-index-key
                        className={
                            images[i + 1] && images[i + 1].colored_border !== image.colored_border && 'dashed'
                        }
                    >
                        <GatsbyImage
                            image={getImage(image.image.localFile)}
                            alt={image.image_alt}
                            style={{
                                border: `${image.colored_border ? colors.darkPurple : colors.black} 3px solid`,
                                boxShadow: `4px 2px 7px 0 ${colors.mediumGrey}`,
                                borderRadius: '25px',
                                cursor: 'pointer',
                                position: 'relative',
                            }}
                            imgStyle={{
                                borderRadius: '25px',
                            }}
                        />
                    </StyledSlide>
                ))}
            </Slider>
            <StyledSlideDescription>
                <p>{images[sliderIndex].description}</p>
            </StyledSlideDescription>
        </div>
    );
};

ChannelImagesCarousel.propTypes = {
    images: PropTypes.array.isRequired,
};

export default ChannelImagesCarousel;
