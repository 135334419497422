/** @jsx jsx */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import {graphql} from 'gatsby';
import {Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';

import ChannelsHero from '../components/channels/ChannelsHero';
import ChannelsList from '../components/channels/ChannelsList';
import ChannelDetails from '../components/channels/ChannelDetails';
import {CTA, EndOfPageCTA} from '../components/common/Clickables';
import SEO from '../components/common/SEO';
import {breakpoints, colors, ContentContainer} from '../styles/theme';

import getCustomizedFilterSchema from '../helpers/channels';
import GeometricPatternBackground from '../images/geometric-pattern-bg.png';
import {H2} from '../components/common/Typography';
import {ContrastContext} from '../helpers/context';


/*
 * Private Elements
 */

// Channels
const ChannelDetailsContentContainer = styled.div`
    padding: 40px 20px 250px;
    background: linear-gradient(50deg, ${colors.transparent} 0%, ${colors.lightGrey} 796px),
                ${colors.lightGrey} repeat-x left bottom/auto 796px url(${GeometricPatternBackground});

    @media (min-width: 885px) {
        padding: 20px 60px 200px;
    }

    ${ContentContainer} {
        @media (max-width: 885px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .channel-entry {
        &:hover {
            cursor: initial;
            box-shadow: none;
        }
    }
`;


const contactSalesCtaCss = css`
    position: relative;
    top: -250px;
    height: 0;

    @media (min-width: ${breakpoints.md}) {
        top: -145px;
    }

    .cta-bottom-background {
        height: 100px;
    }
`;


// Back button
const BackButtonContainer = styled.span`
    @media (max-width: 885px) {
        display: none;
    }
`;


/*
 * Public Elements
 */
const ChannelPage = ({data, location}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();
    const {navigate} = useI18next();

    // Get the channel data
    const channelData = [data.strapiChannel];

    // Get/set the selected filters, if any
    const selectedFilters = location.state?.selectedFilters || {};

    // Process the channels data
    const customizedSchema = getCustomizedFilterSchema(channelData);

    const paymentMethodName = channelData[0].payment_method.name;

    return (
        <>
            <SEO
                title={t('{{paymentMethodName}}', {paymentMethodName})}
                ogTitle={t('Accept {{paymentMethodName}} Payments', {paymentMethodName})}
                description={t('Enable {{paymentMethodName}} automatically. Switch payment methods and providers on '
                    + 'the spot through one single integration.', {paymentMethodName})}
                image="/website/metatags/channels.png"
            />

            <ChannelsHero
                filters={selectedFilters}
                setFilters={filters => {
                    navigate(
                        '/channels',
                        {state: {selectedFilters: {...selectedFilters, ...filters}, scrollToId: 'filters'}},
                    );
                }}
                filtersSchema={customizedSchema}
            />

            <ChannelDetailsContentContainer id="list">
                <ContentContainer>
                    <BackButtonContainer>
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        <CTA to="/channels" state={{selectedFilters, scrollToId: 'list'}} colored invertedDirection>
                            <Trans>Back</Trans>
                        </CTA>
                    </BackButtonContainer>
                    <ChannelsList channels={channelData} />
                    <ChannelDetails channel={channelData[0]} />
                </ContentContainer>
            </ChannelDetailsContentContainer>

            <EndOfPageCTA link="/about/contact-sales" linkText={t('Contact Sales')} cssStyles={contactSalesCtaCss}>
                <H2><Trans>Request this integration.</Trans></H2>
            </EndOfPageCTA>
        </>
    );
};

ChannelPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default ChannelPage;
export const query = graphql`
    query($channelId: String!) {
        strapiChannel(strapiId: { eq: $channelId }) {
            id: strapiId
            merchant_industry
            status
            region
            language
            payment_provider {
                name
                type
                description
                logo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: TRACED_SVG
                                width: 100
                            )
                        }
                    }
                }
                countries {
                    name: name_slug
                }
            }
            payment_method {
                name
                type
                description
                logo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: TRACED_SVG
                                width: 100
                            )
                        }
                    }
                }
                countries {
                    name: name_slug
                }
            }
            screenshots {
                description
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                width: 260
                            )
                        }
                    }
                }
                colored_border
                image_alt
            }
        }
    }
`;
